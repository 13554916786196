<template>
    <div class="card">
      <DataView :value="filteredGames" :layout="layout" :selectedPlatform="selectedPlatform">
          <template #header>
            <div class="header-container">
              <div class="search-container">
                <InputText v-model="search" placeholder="Search games..." />
              </div>
            <div class="flex justify-content-end">
              <img src="../assets/xboxlogo.png" 
                  class="platform-logo" 
                  :class="{ 'active': selectedPlatform === 'xbox' }" 
                  @click="setPlatformFilter('xbox')">
              <img src="../assets/pslogo.png" 
                  class="platform-logo" 
                  :class="{ 'active': selectedPlatform === 'playstation' }" 
                  @click="setPlatformFilter('playstation')">
              <img src="../assets/steamlogoo.png" 
                  class="platform-logo" 
                  :class="{ 'active': selectedPlatform === 'steam' }" 
                  @click="setPlatformFilter('steam')">
            </div>
            </div>
          </template>

            <template #list="slotProps">
                <div class="grid grid-nogutter">
                    <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
                        <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" :class="{ 'border-top-1 surface-border': index !== 0 }">
                            <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" style="cursor: pointer;" @click="goToLink(item.Link)" :src=item.Img :alt="item.Name" />
                            <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                                <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                                    <div class="text-2xl font-bold text-900">{{ item.Name }}</div>                                    
                                </div>
                                <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                    <span class="text-2xl font-semibold">{{ item.Price }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #grid="slotProps">
            <div class="grid">
                <div v-for="(item, index) in slotProps.items" :key="index" class="grid-item">
                <img :src="item.Img" :alt="item.Name" @click="goToLink(item.Link)" style="cursor: pointer;" />
                <div class="platform-icon-container">
                    <img v-if="item.Platform === 'xbox'" src="../assets/xboxlogo.png" class="platform-icon" />
                    <img v-if="item.Platform === 'playstation'" src="../assets/pslogo.png" class="platform-icon" />
                    <img v-if="item.Platform === 'steam'" src="../assets/steamlogoo.png" class="platform-icon" />
                </div>
                <div class="item-title">{{ item.Name }}</div>
                <div class="item-price">{{ formatPrice(item) }}</div>
                </div>
            </div>
            </template>
        </DataView>
    </div>
</template>

  

  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import DataView from 'primevue/dataview';
  import { computed } from 'vue';
  import InputText from 'primevue/inputtext'; // Import InputText

  
  export default {
    components: {
      DataView,
      InputText
    },
    setup() {
      const games = ref([]);
      const layout = ref('grid');
      const selectedPlatform = ref(null);
      const search = ref('');

      const filteredGames = computed(() => {
        let filtered = games.value;
        if (selectedPlatform.value) {
          filtered = filtered.filter(game => game.Platform === selectedPlatform.value);
        }
        if (search.value) {
          filtered = filtered.filter(game => game.Name.toLowerCase().includes(search.value.toLowerCase()));
        }
        return filtered;
      });

      const setPlatformFilter = (platform) => {
        if (selectedPlatform.value === platform) {
          selectedPlatform.value = null;  // Reset the filter if the same platform is clicked again
        } else {
          selectedPlatform.value = platform;
        }
       // console.log('Selected platform:', selectedPlatform.value);
        //console.log('Filtered games:', filteredGames.value);
      };
    const formatPrice = (game) => {
      let currencySymbol = '$'; // Default currency
      if (game.Platform === 'playstation') {
        currencySymbol = 'TRY';
      } else if (game.Platform === 'xbox') {
        currencySymbol = 'ARG';
      }
      return `${currencySymbol} ${game.Price}`;
    };


      const fetchGames = async () => {
        try {
          const response = await axios.get('https://roweflay.xyz:8755/api/games', {
            headers: { Authorization: `Bearer` }
          });
          games.value = response.data; // Update the ref array
         // console.log("Fetched", games.value)
        } catch (error) {
          console.error('Error fetching games:', error);
        }
      };
  
      const goToLink = (url) => {
        window.open(url, '_blank');
      };
  
      onMounted(fetchGames);
  
      return { layout, games, goToLink, filteredGames, setPlatformFilter, formatPrice, search};
    }
  }
  </script>
  
  <style>
  
  .grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust the min width for better layout */
  }

  .grid-item {
    padding: 0.3rem; /* Adjust padding */
  }


  .platform-icon-container {
        width: 25px; /* Smaller size for mobile */
        height: 25px; /* Smaller size for mobile */
        top: 3px; /* Adjusted position for mobile */
        right: 3px; /* Adjusted position for mobile */
    }

    .platform-icon {
        width: auto; /* Adjust width automatically */
        height: 100%; /* Full height of the container */
        object-fit: scale-down; /* Scale down to fit without stretching */
    }


  .grid-item img {
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed grid item width */
    max-height: 100px; /* Set a max height to prevent overly tall images */
  }

  .grid-item .item-title,
  .grid-item .item-price {
    font-size: 0.9rem; /* Adjust font size for better readability */
  }
}

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #414141;
    border-radius: 10px;
    background: var(--surface-card);
    height: 100%;
    max-width: 100%; /* Ensure the grid item does not exceed its cell */
    position: relative;
  }

  .grid-item img {
    max-width: 100%;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
  }

  .grid-item .item-title {
    font-size: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5rem;
    width: 100%; /* Ensure the title does not exceed the grid item */
  }

  .grid-item .item-price {
    font-size: 1.1rem;
    margin-top: 0.25rem;
  }


  html {
    font-size: 14px;
}

body {
    font-family: var(--font-family);
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    padding: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.card {
    background: var(--surface-card);
    padding: 0.3rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

p {
    line-height: 1.75;
}
  /* Additional styles */
  .platform-logo {
  cursor: pointer;
  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image covers the area */
  margin: 5px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
}
  
.platform-logo.active {
    border: 2px solid #4CAF50; /* Example: green border for active filter */
  }

.search-input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px; /* Space between search input and platform logos */
    width: 200px; /* Adjust as needed */
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-container {
    flex-grow: 1; /* Allows the search container to take up available space */
  }

  .filter-container {
    /* Styles for filter icons container */
    display: flex;
    align-items: center;
  }

  /* Styling for the search box */
  .search-container .p-inputtext {
    width: 100%; /* Full width of the container */
    max-width: 400px; /* Maximum width of the search box */
    margin-right: 20px; /* Spacing between search box and filter icons */
  }

  .platform-icon-container {
    position: absolute;
    top: 5px; /* Adjust the position as needed */
    right: 5px; /* Adjust the position as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Container size */
    height: 30px; /* Container size */
    border-radius: 50%; /* Circular shape */
    background: #1e1e1e; /* Background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
    overflow: hidden; /* Ensure no overflow of child elements */
}

.platform-icon {
    max-width: 100%; /* Limit width to container size */
    max-height: 100%; /* Limit height to container size */
    object-fit: contain; /* Entire image is scaled to fit */
}

  </style>
  
 