<template>
    <InvoiceView />
  </template>
  
  <script>
  import InvoiceView from '../components/InvoiceDisplay.vue';
  
  export default {
    components: {
      InvoiceView
    }
  };
  </script>
  