<template>
    <GamesGrid />
  </template>
  
  <script>
  import GamesGrid from '@/components/GamesGrid.vue';
  
  export default {
    components: {
      GamesGrid
    }
  }
  </script>
  