import { defineStore } from 'pinia';
import { signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import router from '@/router'; // Import the router


export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    loginError: null
  }),
  getters: {
    isLoggedIn: (state) => !!state.user
  },
  actions: {
    
    async loginUser(email, password) {
      const auth = getAuth();
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        this.user = userCredential.user;
        this.loginError = null;
        router.push('/');
      } catch (error) {
        this.loginError = error.message;
        this.user = null;
      }
    },
    async logoutUser() {
        const auth = getAuth();
        signOut(auth).then(() => {
            this.user = null;
            router.push('/login');
        }).catch(error => {
            this.loginError = error.message;
            this.user = null;
        });
    },
    setUser(user) {
        this.user = user;
      },
  }
});
