import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import loginPage from '../views/loginPage.vue'
import DatabasePage from '../views/DatabaseView.vue';
import InvoicePage from '../views/InvoicesView.vue'
import GamesView from '../views/GamesView.vue';
import { useAuthStore } from '@/stores/auth'; // Import the auth store

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: "Home"
    },

  },
  {
    path: '/login',
    name: 'login',
    component: loginPage,
    meta: {
      title: "Login"
    }
  },
  {
    path: '/database',
    name: 'database',
    component: DatabasePage,
    meta: {
      requiresAuth: true,
      title: "Database"
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoicePage,
    meta: {
      requiresAuth: true,
      title: "Invoices"
    }
  },
  {
    path: '/games',
    name: 'games',
    component: GamesView,
    meta: {
      title: "Games"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  document.title = to.meta.title || 'Default Title'
  if (to.meta.requiresAuth && !authStore.isLoggedIn) {
    next('/login');
  } else {
    next();
  }
});


export default router
