<template>
    <div class="invoice-display">
        <Message v-if="successMessage" severity="success" >{{ successMessage }}</Message>
        <Message v-if="errorMessage" severity="error" >Error saving invoice! Please double check the values you have set.</Message>
      <DataTable :value="filteredInvoices" dataKey="invoice_id" :paginator="true" :rows="15">
        <template #header>
            <div class="flex justify-content-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="globalFilter" placeholder="Search invoices" />
          </span>
        </div>
        </template>
        

        <Column field="invoiceID" header="Invoice ID">
            <template #body="{ data }">
            <span >{{ data.invoice_id }}</span>
            </template>
        </Column>
        <Column field="ID" header="User ID">
            <template #body="{ data }">
            <span v-if="editableInvoiceId !== data.invoice_id">{{ data.ID }}</span>
            <InputText v-else v-model="data.ID" />
            </template>
        </Column>
        <Column field="item_purchased" header="Item Purchased">
          <template #body="{ data }">
            <span v-if="editableInvoiceId !== data.invoice_id">{{ data.item_purchased }}</span>
            <InputText v-else v-model="data.item_purchased" />
          </template>
        </Column>
        <Column field="amount" header="Amount">
          <template #body="{ data }">
            <span v-if="editableInvoiceId !== data.invoice_id">£{{ data.amount }}</span>
            <InputText v-else v-model="data.amount" />
          </template>
        </Column>
        <Column field="invoice_date" header="Invoice Date" :body="formatDate">
          <template #body="{ data }">
            <span v-if="editableInvoiceId !== data.invoice_id">{{ formatDate(data) }}</span>
            <InputText v-else type="date" v-model="data.invoice_date" />
          </template>
        </Column>
  
        <Column style="width:8rem; text-align:center;">
            <template #body="{ data }">
            <Button v-if="editableInvoiceId !== data.invoice_id" icon="pi pi-pencil" @click="editInvoice(data.invoice_id)" class="p-button-text p-button-rounded p-mr-2" />
            <div v-else>
                <Button icon="pi pi-check" @click="saveInvoice(data)" class="p-button-rounded p-button-success p-button-text p-mr-2" />
                <Button icon="pi pi-times" @click="cancelEdit" class="p-button-rounded p-button-danger p-button-text" />
            </div>
            </template>
        </Column>
      </DataTable>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import axios from 'axios';
  import { getAuth } from 'firebase/auth';
  import Message from 'primevue/message';
  
  export default {
    components: {
      DataTable,
      Column,
      InputText,
      Button,
      Message
    },
    setup() {
      const invoices = ref([]);
      const globalFilter = ref('');
      const editableInvoice = ref(null);
      const editableInvoiceId = ref(null);
      const successMessage = ref('');
      const errorMessage = ref('');
  
      const fetchInvoices = async () => {
        try {
          const auth = getAuth();
          const token = await auth.currentUser.getIdToken();
          const response = await axios.get('https://roweflay.xyz:8755/api/invoices', {
            headers: { Authorization: `Bearer ${token}` }
          });
          invoices.value = response.data;
        } catch (error) {
          console.error("Error fetching invoices:", error);
        }
      };
  
      onMounted(fetchInvoices);
  
      const formatDate = (invoice) => {
        return new Date(invoice.invoice_date).toLocaleDateString();
      };
  
      const filteredInvoices = computed(() => {
        if (!globalFilter.value) return invoices.value;
        return invoices.value.filter((invoice) => {
          return Object.values(invoice).some((field) =>
            field.toString().toLowerCase().includes(globalFilter.value.toLowerCase())
          );
        });
      });
  
      const editInvoice = (invoiceId) => {
      editableInvoiceId.value = invoiceId;
    };

    const saveInvoice = async (invoice) => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        
        // Prepare the invoice data to be updated
        const invoiceData = {
            invoice_id: invoice.invoice_id, // Ensure this field is present in the editableInvoice object
            ID: invoice.ID,
            item_purchased: invoice.item_purchased,
            amount: invoice.amount,
            invoice_date: invoice.invoice_date
        };
            console.log(invoiceData)
        await axios.put('https://roweflay.xyz:8755/api/update-invoice', invoiceData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        console.log('Invoice updated successfully:', invoiceData);
        successMessage.value = 'Invoice updated successfully';
        editableInvoiceId.value = null; // Reset after save
    } catch (error) {
        console.error("Error saving invoice:", error);
        errorMessage.value = "Error saving invoice: " + error.message;

    }
};


    const cancelEdit = () => {
      editableInvoiceId.value = null; // Reset on cancel
    };

  
      return { invoices, globalFilter, editableInvoice, formatDate, filteredInvoices, editInvoice, saveInvoice, cancelEdit, editableInvoiceId, errorMessage, successMessage };
    }
  };
  </script>
  
  <style>
  .invoice-display {
    padding-top: 20px;
    /* Additional styling as needed */
  }
  </style>
  
  