<template>
  <Menubar>
    <template v-slot:start>
      <Button v-if="authStore.isLoggedIn" class="menu-button hide-label-sm" label="Home" icon="pi pi-home" @click="goToHome"/>
      <Button v-if="authStore.isLoggedIn" class="menu-button hide-label-sm" label="Database" icon="pi pi-database" @click="goToDB"/>
      <Button v-if="authStore.isLoggedIn" class="menu-button hide-label-sm" label="Invoices" icon="pi pi-money-bill" @click="goToInvoices"/>
      <Button class="menu-button hide-label-sm" label="Prices" icon="pi pi-tag" @click="goToGames"/>
    </template>

    <template v-slot:end>
      <Button v-if="authStore.isLoggedIn" class="menu-button" label="Logout" icon="pi pi-sign-out" @click="logout" />
      <Button v-else class="menu-button" label="Login" icon="pi pi-sign-in" @click="goToLogin" />
    </template>
  </Menubar>
  <router-view />
</template>


<script>
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';
import { useAuthStore } from '@/stores/auth';

export default {
  name: 'App',
  components: {
    Menubar,
    Button
  },
  
  computed: {
    getMenuItems() {
      //const authStore = useAuthStore();
      return [
        // ... other menu items ...
      ];
    }
  },
  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.logoutUser();
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToHome() {
      this.$router.push('/')
    },
    goToDB () {
      this.$router.push('/database')
    },
    goToInvoices() {
      this.$router.push('/invoices')
    },
    goToGames() {
      this.$router.push('/games')
    }
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  }
  
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #969fa8;
  margin-top: 2px;
}

body {
  padding: 0px;
}

.logout-label {
  color: red;
}

.margin {
  margin-left: 0.1cm;
}

.menu-button {
  background: transparent;
  border: none;
  color: #cccbcb; /* Change as needed */
  margin-left: 0.1cm;
}

.menu-button .p-button-icon {
  color: #cccbcb; /* Change as needed */
}

@media (max-width: 600px) {
    .menu-button {
      font-size: 0; /* Ensures text takes up no space */
      padding: 0; /* Remove padding */
      margin: 0; /* Remove margin */
      width: auto; /* Let the button shrink to fit content */
      min-width: 40px; /* Minimum width to accommodate the icon */
    }
    .menu-button .p-button-icon {
      font-size: 1.1rem; /* Adjust icon size as needed */
    }
    .menu-button .p-button-label {
      display: none; /* Explicitly hide label */
    }
  }

</style>