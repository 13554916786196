<template>
    <div>
      <p v-if="authStore.loginError" class="error" >Invalid Email/Password</p>
      <!-- <Message v-if="authStore.loginError" severity="error">Invalid Email/Password</Message> -->
      <div class="login-form-container">
        
        <form @submit.prevent="submitLogin">
          <div class="p-field">
            <!-- <label for="email">Email</label> -->
            <InputText id="email" type="email" v-model="email" placeholder="Enter your email" />
          </div>
          <div class="p-field">
            <!-- <label for="password">Password</label> -->
            <InputText id="password" type="password" v-model="password" placeholder="Enter your password" />
          </div>
          <Button label="Login" type="submit" />
        </form>
      </div>
    </div>
  </template>
  
  
  <script>
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  //import Message from 'primevue/message';
  import { ref } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  
  export default {
    components: {
      InputText,
      Button,
    //  Message
    },
  
    setup() {
      const email = ref('');
      const password = ref('');
      const authStore = useAuthStore();
      const errorMessage = ref('');
  
      const submitLogin = async () => {
        try {
          await authStore.loginUser(email.value, password.value);
          // Handle the successful authentication
          // this.$router.push('/home');
          // Redirect the user or update the state
        } catch (error) {
          console.error('Login failed:', authStore.loginError);
          errorMessage.value = "Failed to log in: " + authStore.loginError;
          // Handle Errors here.
        }
      };
  
      return {
        email,
        password,
        authStore,
        errorMessage,
        submitLogin
      };
    }
  };
  </script>
  



  
  <style>

  .error {
    color: red;
  }

  .p-field {
    margin-bottom: 1rem; /* Adds space between the fields */
  }
  
  .p-field label {
    display: inline-block;
    min-width: 100px; /* Adjust as needed */
    text-align: right;
    margin-right: 10px; /* Space between label and input box */
  }
  
  .login-form-container {
  background-color: #333; /* Dark background */
  color: #fff; /* Light text */
  padding: 20px;
  border-radius: 5px;
  width: 300px; /* Adjust the width as needed */
  margin: auto; /* Center the form */
}

.p-field {
  margin-bottom: 1rem;
}

.p-field label {
  display: inline-block;
  min-width: 100px;
  text-align: right;
  margin-right: 10px;
  color: #fff; /* Light text for labels */
}
  /* Add any additional styling here */
  </style>
  
  