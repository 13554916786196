<template>
  <div class="login-view">
    <h1>Welcome to Lookup</h1>
    <LoginForm @login="handleLogin"/>
    <!-- Other content for the login page -->
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';

export default {
  components: {
    LoginForm,
  },
  methods: {
    handleLogin(loginData) {
      console.log(loginData);
      // Handle login logic here, e.g., call an API
    }
  }
};
</script>
