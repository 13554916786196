// import { createApp } from 'vue'
// import App from './App.vue'
// import "./firebaseConfig"
// import router from './router'; // Then import the router


// createApp(App).use(router).mount('#app')

import { createApp } from 'vue';
import App from './App.vue';
import './firebaseConfig'; // Import Firebase initialization
import router from './router';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';   // theme
import 'primevue/resources/primevue.min.css';           // core css
import 'primeicons/primeicons.css';                     // icons
import 'primevue/resources/themes/md-dark-indigo/theme.css'; // Example dark them
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import getAuth and onAuthStateChanged
import { useAuthStore } from '@/stores/auth'; // Import useAuthStore



const auth = getAuth();


let app;

onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App)
      .use(createPinia())
      .use(router)
      .use(PrimeVue);

    if (user) {
      const authStore = useAuthStore();
      authStore.user = user; // Set the user
    }

    app.mount('#app');
  }
});