<template>
    <DatabaseView />
  </template>
  
  <script>
  import DatabaseView from '../components/DatabaseDisplay.vue';
  
  export default {
    components: {
      DatabaseView
    }
  };
  </script>
  