<template>
  <Message v-if="successMessage" severity="success" >{{ successMessage }}</Message>
        <Message v-if="errorMessage" severity="error" >Error saving invoice! Please double check the values you have set.</Message>
  <div class="card">
    <DataTable v-model:expandedRows="expandedRows" :value="data" :paginator="true" :rows="14" :filters="filters" dataKey="ID">
      <template #header>
        <div class="flex justify-content-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters.global.value" placeholder="Keyword Search" />
          </span>
        </div>
      </template>
  
        <!-- Data columns with editable fields -->
        <Column field="ID" header="ID">
          <template #body="slotProps">
            <InputText v-if="editableRow === slotProps.data" v-model="slotProps.data.ID" />
            <span v-else>{{ slotProps.data.ID }}</span>
          </template>
        </Column>
        <Column field="Name" header="Name">
          <template #body="slotProps">
            <InputText v-if="editableRow === slotProps.data" v-model="slotProps.data.Name" />
            <span v-else>{{ slotProps.data.Name }}</span>
          </template>
        </Column>
        <Column field="Purchased" header="Purchased">
          <template #body="slotProps">
            <InputText v-if="editableRow === slotProps.data" v-model="slotProps.data.Purchased" />
            <span v-else>{{ slotProps.data.Purchased }}</span>
          </template>
        </Column>
        <Column field="Method" header="Method">
          <template #body="slotProps">
            <InputText v-if="editableRow === slotProps.data" v-model="slotProps.data.Method" />
            <span v-else>{{ slotProps.data.Method }}</span>
          </template>
        </Column>
        <Column field="Amt" header="Amount">
          <template #body="slotProps">
            <InputText v-if="editableRow === slotProps.data" v-model="slotProps.data.Amt" />
            <span v-else>{{ slotProps.data.Amt }}</span>
          </template>
        </Column>
  
        <!-- Edit and Save/Cancel buttons column -->
        <Column style="width:8rem; text-align:center;">
          <template #body="slotProps">
            <Button v-if="editableRow !== slotProps.data" class="p-button-rounded p-button-text" icon="pi pi-pencil" @click="editRow(slotProps.data)" />
            <div v-else>
              <Button class="p-button-rounded p-button-success p-button-text" icon="pi pi-check" @click="saveRow(slotProps.data)" />
              <Button class="p-button-rounded p-button-danger p-button-text" icon="pi pi-times" @click="cancelEdit()" />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </template>
  

<script>
import axios from 'axios';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { FilterMatchMode } from 'primevue/api';
import { getAuth } from 'firebase/auth';
import Message from 'primevue/message';

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Message
  },

  
  data() {
    return {
      data: [],
      editableRow: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ID: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        Purchased: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        Method: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        Amt: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      },
      invoices: [],
      currentUserData: null,
      isDialogVisible: false,
      expandedRows: null,
      dataTableKey: 0,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    editRow(rowData) {
      this.editableRow = rowData;
    },
    async saveRow(rowData) {
        try {
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            
            await axios.put('https://roweflay.xyz:8755/api/update-user', rowData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Assuming you want to refresh the data after update
           // this.fetchData();
            this.editableRow = null;
            this.successMessage = 'User updated successfully'; // Set success message
            this.errorMessage = '';
        } catch (error) {
            console.error("Error updating data:", error);
            this.errorMessage = 'Error updating user: ' + error.message; // Set error message
            this.successMessage = ''; 
            // Handle error (e.g., show a notification to the user)
        }
    },
    cancelEdit() {
      this.editableRow = null;
    },
    isRowEditable(rowData) {
        console.log(rowData)
      return this.editableRow && this.editableRow.ID === rowData.ID;
    },
    updateUserInvoices(userId, invoices) {
      const dataIndex = this.data.findIndex(d => d.ID === userId);
      if (dataIndex !== -1) {
        const updatedUserData = { ...this.data[dataIndex], invoices: invoices };
        this.data[dataIndex] = updatedUserData;
        // Increment the key to force update the DataTable
        this.dataTableKey++;
      }
    },

    async showInvoices(userData) {
      try {
        const invoices = await this.fetchInvoices(userData.ID);
        if (invoices.length) {
          this.currentUserData = { ...userData, invoices: invoices };
          // Force update the DataTable
          this.dataTableKey++;
        } else {
          this.currentUserData = { ...userData, invoices: [] };
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
        this.currentUserData = { ...userData, invoices: [] };
      }
    },


    editInvoice(invoiceData) {
      this.editableInvoice = invoiceData;
    },
    async saveInvoice(invoiceData) {
      console.log(invoiceData)
      // Save invoice logic here
      this.editableInvoice = null;
    },
    cancelInvoiceEdit() {
      this.editableInvoice = null;
    },
    async fetchInvoices(userId) {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      try {
        const response = await axios.get(`https://roweflay.xyz:8755/api/invoices/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching invoices:", error);
        return [];
      }
    },
  },
  async created() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdToken();
        const response = await axios.get('https://roweflay.xyz:8755/api/data', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.data = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      console.error("User not logged in");
    }
  }
}

</script>
  
  
<style>
.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f4f4f4;
}

.flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.p-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .p-col-12 {
    flex: 0 0 100%;
  }

  .p-md-6 {
    flex: 0 0 50%;
  }

  .card {
    padding-top: 20px;
  }

/* Additional styling as needed */
</style>