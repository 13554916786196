// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAd8BlHm2GlDI7nKJ5h4nloe87xn94qCxI",
  authDomain: "thegentleman-30559.firebaseapp.com",
  databaseURL: "https://thegentleman-30559-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "thegentleman-30559",
  storageBucket: "thegentleman-30559.appspot.com",
  messagingSenderId: "311840221026",
  appId: "1:311840221026:web:7e2e8a493626812cf06e3b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };